import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Modal, Box,
    Stack, Button,
    ClickAwayListener,
    Typography,
} from "@mui/material";
import axios from 'axios';
import { useOktaAuth } from '@okta/okta-react';


const backend_base_url = process.env.REACT_APP_BACKEND_API_URL;

const fetchData = async (accessToken, userInfo, searchedSerial, ip, barcodeFilePath) => {
    try {
        // Dynamically build the params object based on whether barcodeFilePath has a value
        const params = {
            'SerialNo': searchedSerial,
            'userID': userInfo.preferred_username,
            'ip': ip,
            'source': 'web',
            'bearer_token': accessToken
        };

        if (barcodeFilePath) {
            params['barcode_path'] = barcodeFilePath;
        }

        const [response_json, response_excel] = await Promise.all([
            axios.get(`${backend_base_url}/api/STSProDataQuery/2d-pro-traceability-realtime`, {
                params
            }),
            axios.get(`${backend_base_url}/api/STSProDataQuery/2d-pro-traceability-download`, {
                params,
                responseType: 'blob'
            })
        ]);

        return {
            jsonAPIData: response_json.data,
            excelAPIData: response_excel.data
        };

    } catch (error) {
        //console.error("Error fetching data:", error);
        return { error: 'not_found' };
    }
};

const WhiteButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    color: "black",
    '&:hover': {
        backgroundColor: '#7ad2fa',
    },
}));

const SearchBox = styled('div')(({ theme, expanded }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius * 3,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(0.5, 1),
    width: '100%',
    height: '6ch'
}));

const SearchInput = styled(InputBase)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "black",
    fontWeight: 'bolder',
    fontSize: '18px'
}));

const BarcodeSearch = ({ onSearch, downloadSearch, barcodeSn, barcodeFilePath, resetBarcodeSn, ip, authState }) => {
    const [open, setOpen] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [accessToken, setAccessToken] = useState(null);

    const [errorModal, setErrorModal] = useState(false);
    const handleClose = () => setErrorModal(false);
    // const { authState, oktaAuth } = useOktaAuth();

    //console.log(barcodeFilePath)

    const handleClick = () => {
        setOpen(true);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const handleTextChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleSearch = async () => {
        //console.log('Search Text:', searchText);
        setLoading(true);

        try {
            const data = await fetchData(accessToken, userInfo, searchText, ip, barcodeFilePath || undefined);
            if (data.error === 'not_found') {
                setErrorModal(true);
            } else {
                //console.log('Data from JSON API:', data.jsonAPIData);
                //console.log('Data from EXCEL API:', data.excelAPIData);
                onSearch(data.jsonAPIData);
                downloadSearch(data.excelAPIData);
                resetBarcodeSn();
            }
        } catch (error) {
            //console.error("Error during search:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (barcodeSn === '') {
            resetBarcodeSn();
        } else if (barcodeSn) {
            setSearchText(barcodeSn); 
        }
    }, [barcodeSn]);

    useEffect(() => {
        if (barcodeSn && searchText) {
            handleSearch();
        }
    }, [searchText]);

    if (authState && authState.isAuthenticated && !userInfo) {
        setUserInfo(authState.idToken.claims);
        setAccessToken(authState.accessToken.accessToken);
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Stack
                direction={{ xs: 'column', sm: 'row', md: 'row' }}
                justifyContent="space-between"
                spacing={2}
                sx={{
                    position: 'absolute',
                    top: { xs: '40%', sm: '50%' },
                    left: { xs: '50%', sm: '4%', md: '4%', lg: '4%', xl: '4%' },
                    transform: { xs: 'translate(-50%, -50%)', sm: 'none' },
                    width: { xs: '80%', sm: '75%', md: '50%', lg: '40%', xl: '30%' },
                    zIndex: 1,
                    textAlign: { xs: 'center', sm: 'left' }
                }}
            >
                <SearchBox expanded={open}>
                    <SearchInput
                        placeholder="Input your barcode here ..."
                        value={searchText}
                        onChange={handleTextChange}
                        onClick={handleClick}
                    />
                </SearchBox>
                <WhiteButton
                    type='button'
                    variant="contained"
                    startIcon={loading ? <CircularProgress size={24} color="inherit" /> : <SearchIcon />}
                    disabled={loading}
                    onClick={handleSearch}
                    sx={{
                        borderRadius: 5,
                        width: { xs: '50%', sm: '30%' },
                        alignSelf: 'center',
                        '& .MuiCircularProgress-svg': {
                            color: 'white', // Color of CircularProgress
                        },
                    }}
                >
                    <Typography
                        variant='body1'
                        textAlign='center'
                        color="black"
                        fontWeight="bolder"
                        fontSize='large'
                        sx={{ textTransform: 'capitalize', color: 'inherit' }}
                    >
                        {loading ? 'Searching...' : 'Search'}
                    </Typography>
                </WhiteButton>
                <Modal
                    open={errorModal}
                    onClose={handleClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'gray', // Gray background
                            borderRadius: 4, // Rounded corners for the modal
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <Typography
                            id="modal-title"
                            variant="h6"
                            component="h2"
                            sx={{
                                color: 'white',
                                fontWeight: 'bold', // Similar bold font as in the example
                                fontSize: '1.25rem',
                            }}
                        >
                            Data Not Found.
                        </Typography>
                        <Typography
                            id="modal-description"
                            sx={{
                                mt: 2,
                                color: 'white',
                                fontWeight: 'bold', // Bold font similar to the example
                                fontSize: '1rem',
                            }}
                        >
                            Please contact your FAE for assistance.
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end', // Align button to the bottom-right
                                mt: 4,
                            }}
                        >
                            <button
                                onClick={handleClose}
                                style={{
                                    backgroundColor: 'white', // White background for the button
                                    color: 'black', // Black font color for the button
                                    fontWeight: 'bold', // Bold font for the button
                                    border: 'none',
                                    borderRadius: '20px', // Rounded button sides
                                    padding: '8px 16px',
                                    cursor: 'pointer',
                                }}
                                onMouseOver={(e) => {
                                    e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.9)'; // Hover effect
                                }}
                                onMouseOut={(e) => {
                                    e.currentTarget.style.backgroundColor = 'white'; // Reset on hover out
                                }}
                            >
                                Close
                            </button>
                        </Box>
                    </Box>
                </Modal>
            </Stack>
        </ClickAwayListener>
    );
}

export default BarcodeSearch;
